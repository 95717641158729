import React from "react"


export default class Layer extends React.Component {
    render() {


        return (
            <>
                <path className={'single-layer'} d="M3.3,8.5H37.2c0.72,0,1.3-0.67,1.3-1.5V3.25c0-0.83-0.58-1.5-1.3-1.5H3.3C2.58,1.75,2,2.42,2,3.25V7
	            C2,7.83,2.58,8.5,3.3,8.5z"/>
            </>
        )
    }
}
