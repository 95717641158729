import React from 'react'
import {graphql, Link, useStaticQuery} from 'gatsby'
import CookieConsent, {Cookies} from "react-cookie-consent";
import {useLocation} from "@reach/router"
import {initializeAndTrack} from 'gatsby-plugin-gdpr-cookies'


const query = graphql`
 {
    allStrapiLegal {
        nodes {
           slug
           title
           locale
         }
     }
    strapiCookiebanner {
        legallink {
          legal {
            slug
          }
          linktext
        }
        cookiecontent
        successbutton
        declinebutton
      }
  }
`


const Footer = () => {

    const location = useLocation()
    const data = useStaticQuery(query)
    const {
        allStrapiLegal: {nodes},
        strapiCookiebanner: {cookiecontent, successbutton, declinebutton, legallink: {legal: {slug}, linktext}}
    } = data


    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-area bg-color">
            <div className="container">
                <div className="footer-bottom-area">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <p>Copyright © {currentYear} | <strong>Cardizoo®</strong> All rights reserved</p>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <ul>
                                {nodes.map((legal, idx) => {
                                    return (
                                        <li key={idx}>
                                            <Link to={`/${legal.slug}`}>
                                                {legal.title}
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <CookieConsent

                enableDeclineButton
                location="bottom"
                buttonClasses="gdpr-button"
                containerClasses="gdpr-container"
                contentClasses="gdpr-content"
                buttonText={successbutton}
                declineButtonText={declinebutton}
                cookieName="gatsby-gdpr-google-tagmanager"
                style={{
                    background: "white",
                    display: "flex",
                    padding: "10px 15px 20px",
                    color: "black",
                    fontSize: "16px",
                    margin: "0",
                    alignItems: "end",
                }}
                buttonStyle={{
                    display: "inline-flex",
                    background: "#e778ab",
                    color: "#fff",
                    borderRadius: "4px",
                    padding: "12px 25px",

                    justifyContent: "middle",
                    cursor: "pointer",
                    fontSize: "16px",
                    "&:hover": {
                        background: "#ffcc00",
                    },
                }}

                declineButtonStyle={{
                    display: "inline-flex",
                    background: "#fff",
                    borderRadius: "4px",
                    color: "#000",
                    cursor: "pointer",
                    fontSize: "10px",
                    textAlign: "center",
                    marginRight: "5px",
                    verticalAlign: "bottom"
                }}

                acceptOnScroll={true}
                acceptOnScrollPercentage={40}
                onAccept={(acceptedByScrolling) => {
                    initializeAndTrack(location)
                }}
            >
                {cookiecontent} <Link to={`/${slug}`}> {linktext}</Link>

            </CookieConsent>
        </footer>
    );
}


export default Footer;
