import React from 'react';
import { useRecoilState } from 'recoil'
import { collapsedState } from '../../utils/recoil-atoms'
import {graphql, Link, useStaticQuery} from 'gatsby'
import logo from "../../assets/images/logo.png"
import Layers from "./Burger-Button/Layers";



const Navbar = () => {
    const data = useStaticQuery(query)
    const {
        allStrapiSymptom: {nodes},
        strapiSettings: {linkname_symptoms, linkname_button, linkname_blog, linkname_contact, url}
    } = data

    const [collapsed, setCollapsed] = useRecoilState(collapsedState);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

    const [isOpen, setIsOpen] = React.useState(false);
    const toggleSubmenu = () => {
        setIsOpen(!isOpen)
    }

    React.useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    })

    const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <React.Fragment>
            <div id="navbar" className="navbar-area navbar-style-two">
                <div className="tarn-nav">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <Link 
                                to="/"
                                onClick={() => setCollapsed(true)}
                                className="navbar-brand"
                            >
                                <img src={logo} alt="logo" />
                            </Link>

                            <button 
                                onClick={toggleNavbar} 
                                className={classTwo}
                                type="button" 
                                data-toggle="collapse" 
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
                                aria-expanded="false" 
                                aria-label="Toggle navigation"
                            >
                                <svg>
                                    <Layers/>
                                </svg>
                            </button>

                            <div className={classOne} id="navbarSupportedContent">
                                 <ul className="navbar-nav">

                                    <li className={`nav-item ${isOpen ? 'show-submenu' : ''}`}>
                                        <Link 
                                            to="/symptoms"
                                            activeClassName="active"

                                            className="nav-link"
                                        >
                                            {linkname_symptoms} <i className='bx bx-chevron-down chevron-large'  onClick={toggleSubmenu}></i>
                                        </Link>
                                        <i className='bx bx-chevron-down chevron-mobile'  onClick={toggleSubmenu}></i>
                                        
                                        <ul className="dropdown-menu">
                                            {nodes.map((symptomLink, idx)=>{
                                                return(
                                                    <li className="nav-item" key={idx}>
                                                        <Link
                                                            to={`/symptoms/${symptomLink.slug}`}
                                                            activeClassName="active"
                                                            onClick={() => setCollapsed(true)}
                                                            className="nav-link"
                                                        >
                                                            {symptomLink.title}
                                                        </Link>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </li>

                                     <li className="nav-item">
                                         <Link
                                             to="/contact"
                                             activeClassName="active"
                                             onClick={() => setCollapsed(true)}
                                             className="nav-link"
                                         >
                                             {linkname_contact}
                                         </Link>

                                     </li>

                                     {/*
                                    <li className="nav-item">
                                        <Link
                                            to="/blog"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="nav-link"
                                        >
                                            {linkname_blog}
                                        </Link>

                                    </li>
                                    */}

                                </ul>
                            
                                <div className="others-option d-flex align-items-center">
                                    <div className="option-item">
                                        <Link 
                                            to={`${url}`}
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                        >
                                            <i className="flaticon-right"></i> {linkname_button} <span></span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const query = graphql`
    {
      allStrapiSymptom {
        nodes {
          slug
          title
          locale
        }
      }
      strapiSettings {
        linkname_symptoms
        linkname_button
        linkname_blog
        linkname_contact
        url
        locale
      }
    }
`


export default Navbar
