import React from "react"
import Layer from "./Layer"

export default class Layers extends React.Component {


    render() {
        const layers = [1, 2, 3].reverse()
        return layers.map((value, index) => {
            return (
                <g
                    key={value}
                >
                    <Layer />
                </g>
            )
        })
    }
}
